<template>
  <div class="popular">
    <div class="popular-title">
    <span>科普知识</span>
    <span @click="jumpToimgdetail(propulartitle)">>>&nbsp;更多</span>
    </div>
    <div class="popular-container">
      <div class="popularlists">
        <ul>
            <li
            v-for="(item,index) in getpopularlist"
            :key="index"
            @click="$goto('/detailShow',{id:item.id})"
            >
            <p>
              {{item.title}}
              <i class="pic"></i>
              <i class="video"></i>
            </p>
<!--            <span>{{item.publishDate | formateData}}</span>-->
            </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    getpopularlist: {
      type: Array,
      default: ()=>[]
    },
    propulartitle: {
      type: String,
      default: ()=>"捐献科普"
    }
  },
  name: 'Popular',
  data () {
    return {

    }
  },
  methods: {
    jumpToimgdetail (propulartitle) {
      if(propulartitle === '造干科普') {
        this.$router.push({
        path: '/textDetail',
        query: {name: '科普知识', type: 'zgxb_kpzs'}
      })
      } else if(propulartitle === '捐献科普') {
        this.$router.push({
        path: '/textDetail',
        query: {name: '科普知识', type: 'ytqgjm_kpzs'}
      })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.popular {
    .popular-title  {
        cursor: pointer;
        margin-top: 53px;
        font-size: 23px;
        padding-right: 25px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        span {
            &:nth-child(1) {
            display: flex;
                &::before {
                content: "";
                display: block;
                height: 20px;
                width: 35px;
                background: url('../../../assets/img/title.png') no-repeat center center;
                }
            }
            &:nth-child(2) {
                font-size: 17px;
                color: #ABABAB;
            }
        }
    }
    .popular-container {
        width: 1137px;
        padding: 39px 23px 39px 40px;
        min-height: 402px;
        background-color: #ffffff;
        box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
        .popularlists{
            li {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px !important;
				padding: 10px 0;
                p {
                    display: flex;
                    color: #333333;
					&:hover {
						color: #d1292e;
					}
                     &::before {
                    content: "";
                    display: block;
                    width: 16px;
                    height: 16px;
                    background: url('../../../assets/img/list-little.png') no-repeat 0 center;
                   }
                   .pic {
                     cursor: pointer;
                     display: block;
                     width: 22px;
                     height: 18px;
                     background: url('../../../assets/img/pic.png') no-repeat 0 center;
                     margin-left: 10px;
                   }
                   .video {
                     cursor: pointer;
                     display: block;
                     width: 22px;
                     height: 18px;
                     background: url('../../../assets/img/video.png') no-repeat 0 center;
                     margin-left: 10px;
                   }
                }
                span {
                    color: #ABABAB;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
          }
    }
}
</style>
