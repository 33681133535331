<template>
  <div class="bodydonate">
    <div class="body-container">
      <Popular :getpopularlist="popularlist"></Popular>
    </div>
  </div>
</template>

<script>
import Popular from './components/popular'
import apiUrls from '@api'
export default {
  components: {
    Popular
  },
  name: 'Bodydonate',
  data () {
    return {
      popularlist: [],
    }
  },
  created () {
    this.getpopular()
  },
  methods: {
    getpopular() {
      apiUrls.getbodydonate({node: 'ytqgjm_kpzs'}).then(res=>{
      this.popularlist= res.results.data
      })
    },
  }
}
</script>

<style lang="less" scoped>
.bodydonate {
  background-color: #f5f5f5;
  .body-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;

    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden; 
    }
  }
}
</style>
